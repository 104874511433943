<template>
  <v-card flat class="segmented" v-if="!loading">
    <v-card-text>
      <v-alert v-if="!readOnly" text type="info">
        {{ $t('careerDevelopment.comparisonInfo') }}
      </v-alert>

      <v-row>
        <v-col cols="12" lg="6" class="d-flex" style="flex-direction: column">
          <comparison-section
            :feedbackData="selfEvaluationData"
            :feedbackType="feedbackTypes.SELF_EVALUATION"
            :positions="positions"
            :readOnly="readOnly || printView"
            @selectData="selectData"
            ref="selfEvalData"
          ></comparison-section>
        </v-col>

        <v-col cols="12" lg="6" class="d-flex" style="flex-direction: column">
          <comparison-section
            :feedbackData="donorEvaluationData"
            :feedbackType="feedbackTypes.MANAGER_EVALUATION"
            :positions="positions"
            :readOnly="readOnly || printView"
            @selectData="selectData"
            ref="donorEvalData"
          ></comparison-section>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { feedbackEnums } from '@/enums/feedbacks.js';

import comparisonSection from './comparison-section.vue';

export default {
  props: {
    positions: {
      type: Array,
      required: true
    },

    feedbackData: {
      type: Object,
      required: true
    },

    isFormFilled: {
      type: Boolean,
      required: false,
      default: false
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    feedbackTypes: feedbackEnums.feedbackTypes,
    selfEvaluationData: null,
    donorEvaluationData: null,
    loading: true
  }),

  watch: {
    isFormFilled() {
      if (this.isFormFilled && !this.printView) {
        this.collapseAll();
      }
    }
  },

  async mounted() {
    const payload = {
      feedbackId: this.$route.params.id,
      processId: this.feedbackData.processId
    };

    const comparisonData = await this.getComparisonData(payload);
    this.selfEvaluationData = JSON.parse(comparisonData.selfEvaluationData);
    this.donorEvaluationData = JSON.parse(comparisonData.donorEvaluationData);

    this.loading = false;
  },

  methods: {
    ...mapActions({
      getComparisonData: 'careerDevelopment/getComparisonData'
    }),

    collapseAll() {
      this.$refs.selfEvalData.collapse();
      this.$refs.donorEvalData.collapse();
    },

    selectData(data) {
      this.$emit('selectData', data);
    }
  },

  components: {
    comparisonSection
  }
};
</script>
