<template>
  <v-expansion-panel @click="$emit('panelClicked')">
    <v-expansion-panel-header
      disable-icon-rotate
      :disabled="readOnly && !preview"
      @click="$emit('selected')"
    >
      <template v-slot:actions v-if="selectedCareerPath">
        <v-icon>mdi-check-circle</v-icon>
      </template>

      {{ $t(`careerDevelopment.careerPath.${careerPathType}`) }}
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pt-4">
      <v-row>
        <v-col cols="12" md="5" lg="3">
          {{ $t('careerDevelopment.targetPosition') }}
        </v-col>
        <v-col cols="12" md="7" lg="9">
          <v-select
            v-model="value.targetPosition"
            :placeholder="$t('pleaseSelect')"
            :items="positionOptions"
            :readonly="readOnly"
            hide-details
            filled
            flat
            @blur="$emit('change')"
          >
          </v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="5" lg="3">
          {{ $t(`careerDevelopment.availableSkills.${feedbackType}`) }}
        </v-col>
        <v-col cols="12" md="7" lg="9">
          <v-textarea
            v-model="value.availableSkills"
            :label="$t('comment')"
            :readonly="readOnly"
            :rows="3"
            auto-grow
            filled
            hide-details
            @blur="$emit('change')"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="5" lg="3">
          {{ $t(`careerDevelopment.missingSkills.${feedbackType}`) }}
        </v-col>
        <v-col cols="12" md="7" lg="9">
          <v-textarea
            v-model="value.missingSkills"
            :label="$t('comment')"
            :readonly="readOnly"
            :rows="3"
            auto-grow
            filled
            hide-details
            @blur="$emit('change')"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="5" lg="3">
          {{ $t(`careerDevelopment.promotionTime`) }}
        </v-col>
        <v-col cols="12" md="7" lg="9">
          <datepicker
            v-model="value.promotionTime"
            :readOnly="readOnly"
            filled
            :label="$t('pleaseSelect')"
            :hint="$t(`careerDevelopment.dateHint.${careerPathType}`)"
            @change="dateChanged"
          ></datepicker>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-alert
            type="info"
            text
            v-html="
              $t(`careerDevelopment.promotionProcessInfo.${careerPathType}`)
            "
            class="mt-2"
          >
          </v-alert>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import datepicker from '@/components/misc/datepicker.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    careerPathType: {
      type: Number,
      required: true
    },

    positions: {
      type: Array,
      required: true
    },

    selectedCareerPath: {
      type: Boolean,
      required: true
    },

    feedbackType: {
      type: Number,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    preview: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    positionOptions() {
      const positions = this.positions.filter(
        (x) => x.type === this.careerPathType
      );
      positions.sort((x, y) => x.sortingIndex - y.sortingIndex);
      return positions.map((x) => {
        return {
          value: x.id,
          text: this.localize(x.name)
        };
      });
    }
  },

  methods: {
    dateChanged() {
      this.$emit('dateChanged', this.value.promotionTime);
    }
  },

  components: {
    datepicker
  }
};
</script>

<style lang="scss" scoped></style>
