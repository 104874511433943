<template>
  <v-expansion-panel @click="$emit('panelClicked')">
    <v-expansion-panel-header
      disable-icon-rotate
      :disabled="readOnly && !preview"
      @click="$emit('selected')"
    >
      <template v-slot:actions v-if="selectedPathType === pathType">
        <v-icon>mdi-check-circle</v-icon>
      </template>

      {{ $t(`careerDevelopment.lateralPath.${pathType}`) }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" md="5" lg="3">
          {{ $t(`careerDevelopment.positionLabel.${pathType}`) }}
        </v-col>
        <v-col cols="12" md="7" lg="9">
          <v-text-field
            v-model="value.position"
            :label="$t('comment')"
            :readonly="readOnly"
            filled
            :flat="readOnly"
            hide-details
            @blur="$emit('change')"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="5" lg="3">
          {{ $t(`careerDevelopment.availableSkills.${feedbackType}`) }}
        </v-col>
        <v-col cols="12" md="7" lg="9">
          <v-textarea
            v-model="value.availableSkills"
            :label="$t('comment')"
            :readonly="readOnly"
            :rows="3"
            filled
            hide-details
            auto-grow
            @blur="$emit('change')"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="5" lg="3">
          {{ $t(`careerDevelopment.missingSkills.${feedbackType}`) }}
        </v-col>

        <v-col cols="12" md="7" lg="9">
          <v-textarea
            v-model="value.missingSkills"
            :label="$t('comment')"
            :readonly="readOnly"
            :rows="3"
            filled
            hide-details
            auto-grow
            @blur="$emit('change')"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="5" lg="3">
          {{ $t(`careerDevelopment.timetableLabel`) }}
        </v-col>

        <v-col cols="12" md="7" lg="9">
          <datepicker
            v-model="value.timetable"
            :label="$t('comment')"
            :readOnly="readOnly"
            filled
            @change="$emit('change')"
          ></datepicker>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-alert
            type="info"
            text
            v-html="$t(`careerDevelopment.jobboardInfo`)"
            class="mt-4"
          >
          </v-alert>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import datepicker from '@/components/misc/datepicker.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    pathType: {
      type: Number,
      required: true
    },

    selectedPathType: {
      type: Number,
      required: true
    },

    feedbackType: {
      type: Number,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    preview: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  components: {
    datepicker
  }
};
</script>
