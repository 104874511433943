<template>
  <div class="d-flex flex-column flex-grow-1">
    <v-sheet
      class="career-comparison__header__sheet pa-4 elevation-4 d-flex"
      @click="expanded = !expanded"
    >
      {{ $t('careerDevelopment.dataFromFeedback') }}
      {{ feedbackTitle }}

      <v-spacer></v-spacer>
      <v-icon>mdi-chevron-down</v-icon>
    </v-sheet>

    <v-expand-transition>
      <v-sheet
        rounded
        class="pa-1 mt-0 pt-2 pb-4 career-comparison__sheet flex-column flex-grow-1 align-content-start"
        v-show="expanded"
      >
        <template v-if="feedbackData">
          <v-row>
            <v-col cols="12" class="pb-0 mb-0">
              <div class="pl-4 mt-2">
                {{
                  $t(
                    `careerDevelopment.developmentType.${feedbackData.development}`
                  )
                }}
                <v-icon>mdi-chevron-right</v-icon>
                {{ getDevelopmentPath(feedbackData) }}

                <template v-if="feedbackData.development === 1">
                  <v-icon>mdi-chevron-right</v-icon>
                  {{ getPositionName(feedbackData) }}
                </template>
              </div></v-col
            ></v-row
          >

          <v-row class="mt-0 pt-0">
            <v-col cols="12">
              <div v-html="getSummary(feedbackData)" class="pl-4"></div>
            </v-col>
          </v-row>

          <div v-if="!readOnly" class="mt-4 d-flex justify-center align-end">
            <v-btn @click="transferToForm(feedbackData)">
              <v-icon small class="mr-2">mdi-plus-box-multiple</v-icon>
              {{ $t('careerDevelopment.transferSelection') }}
            </v-btn>
          </div>
        </template>
        <i v-else class="muted">
          {{ $t('noDataAvailable') }}
        </i>
      </v-sheet>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  props: {
    feedbackType: {
      type: Number,
      required: true
    },

    feedbackData: {
      type: Object,
      required: false,
      default: () => null
    },

    positions: {
      type: Array,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    expanded: true
  }),

  computed: {
    feedbackTitle() {
      if (this.feedbackData?.title?.length > 0 ?? false) {
        return this.localize(this.feedbackData.title);
      }

      return this.$t(`feedbacks.typeOptions.${this.feedbackType}`);
    }
  },

  methods: {
    collapse() {
      this.expanded = false;
    },

    getDevelopmentPath(feedback) {
      var devPath = feedback.data.developmentPath;

      if (feedback.development === 0) {
        return this.$t(`careerDevelopment.lateralPath.${devPath}`);
      } else if (feedback.development === 1) {
        return this.$t(`careerDevelopment.careerPath.${devPath}`);
      } else {
        return '';
      }
    },

    getPositionName(feedback) {
      if (!feedback) {
        return '';
      }

      const positionId = feedback.data.developmentData.targetPosition;
      const position = this.positions.filter((x) => x.id === positionId);

      if (position.length === 0) {
        return '';
      }

      return this.localize(position[0].name);
    },

    getSummary(feedback) {
      const developmentData = feedback.data.developmentData;
      const keys = Object.keys(developmentData);

      let result = '';

      for (let key of keys) {
        // skip target position property because we already added the target
        // position to the development path
        if (key === 'targetPosition') {
          continue;
        }

        var label = this.$t(`careerDevelopment.${key}Label`);
        if (typeof label !== 'string') {
          label = label[feedback.data.developmentPath];
        }

        const value = this.getValue(developmentData[key]);
        result += `<div class="v-subheader theme--light ml-0 pl-0">${label}</div><div class="mb-4">${value}</div>`;
      }

      return result;
    },

    getValue(data) {
      if (!data) {
        return this.$t('notAvailable');
      }
      try {
        return this.$d(new Date(data), 'date');
      } catch {
        return data;
      }
    },

    transferToForm(feedback) {
      const copy = JSON.parse(JSON.stringify(feedback));
      this.$emit('selectData', copy);
    }
  }
};
</script>

<style lang="scss" scoped>
.career-comparison__sheet {
  background-color: rgba(0, 159, 218, 0.05);
  display: flex;
}

.career-comparison__header__sheet {
  background-color: rgba(0, 159, 218, 0.25);
}

.career-comparison__header__sheet:hover {
  cursor: pointer;
}
</style>
