<template>
  <div>
    <v-expansion-panels
      v-model="openPanel"
      flat
      focusable
      :disabled="readOnly && !preview"
      :readonly="true"
    >
      <v-expansion-panel @click="panelClicked(0)">
        <v-expansion-panel-header disable-icon-rotate :disabled="readOnly">
          <template v-slot:actions v-if="developmentPath === 0">
            <v-icon>mdi-check-circle</v-icon>
          </template>
          {{ $t('careerDevelopment.lateralPath.0') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          <v-row>
            <v-col cols="12" md="5" lg="3">
              <div v-html="$t('careerDevelopment.competencyFocusLabel')"></div>

              <v-tooltip right max-width="500">
                <template v-slot:activator="{ on: tooltip }">
                  <v-icon v-on="{ ...tooltip }">mdi-information</v-icon>
                </template>
                <span
                  v-html="$t('careerDevelopment.competencyFocusInfo')"
                ></span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" md="7" lg="9">
              <v-textarea
                v-model="developmentData[0].competencyFocus"
                :label="$t('comment')"
                :readonly="readOnly"
                :rows="3"
                hide-details
                filled
                auto-grow
                @blur="emitChange"
              ></v-textarea>

              <!-- <v-alert type="info" text class="mt-4">
                {{ $t('careerDevelopment.competencyFocusInfo') }}
              </v-alert> -->
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="5" lg="3">
              {{ $t('careerDevelopment.responsibilitiesLabel') }}
              <v-tooltip right max-width="500">
                <template v-slot:activator="{ on: tooltip }">
                  <v-icon v-on="{ ...tooltip }">mdi-information</v-icon>
                </template>
                <span
                  v-html="$t('careerDevelopment.responsibilitiesInfo')"
                ></span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" md="7" lg="9">
              <v-textarea
                v-model="developmentData[0].responsibilities"
                :label="$t('comment')"
                :readonly="readOnly"
                :rows="3"
                filled
                hide-details
                auto-grow
                @blur="emitChange"
              ></v-textarea>

              <!-- <v-alert type="info" text class="mt-4">
                {{ $t('careerDevelopment.responsibilitiesInfo') }}
              </v-alert> -->
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <lateral-path
        v-model="developmentData[1]"
        :pathType="1"
        :selectedPathType="developmentPath"
        :feedbackType="feedbackType"
        :readOnly="readOnly"
        :preview="preview"
        @change="emitChange"
        @panelClicked="panelClicked(1)"
      />

      <lateral-path
        v-model="developmentData[2]"
        :pathType="2"
        :selectedPathType="developmentPath"
        :feedbackType="feedbackType"
        :readOnly="readOnly"
        :preview="preview"
        @change="emitChange"
        @panelClicked="panelClicked(2)"
      />

      <lateral-path
        v-model="developmentData[3]"
        :pathType="3"
        :selectedPathType="developmentPath"
        :feedbackType="feedbackType"
        :readOnly="readOnly"
        :preview="preview"
        @change="emitChange"
        @panelClicked="panelClicked(3)"
      />

      <lateral-path
        v-model="developmentData[4]"
        :pathType="4"
        :selectedPathType="developmentPath"
        :feedbackType="feedbackType"
        :readOnly="readOnly"
        :preview="preview"
        @change="emitChange"
        @panelClicked="panelClicked(4)"
      />
    </v-expansion-panels>

    <change-path-dialog
      :showDialog="changeModal"
      @closed="changeModal = false"
      @continue="acceptDialog"
    ></change-path-dialog>
  </div>
</template>

<script>
import lateralPath from './lateral/lateral-path.vue';
import changePathDialog from './change-path-dialog.vue';

export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {}
    },

    positions: {
      type: Array,
      required: false,
      default: () => []
    },

    feedbackType: {
      type: Number,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    preview: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      openPanel: null,
      changeModal: false,
      requestedDevelopmentPath: null,
      developmentPath: -1,
      improvement: {},
      developmentData: {
        0: {},
        1: {
          position: null,
          availableSkills: null,
          missingSkills: null,
          timetable: null
        },
        2: {
          position: null,
          availableSkills: null,
          missingSkills: null,
          timetable: null
        },
        3: {
          position: null,
          availableSkills: null,
          missingSkills: null,
          timetable: null
        },
        4: {
          position: null,
          availableSkills: null,
          missingSkills: null,
          timetable: null
        }
      }
    };
  },

  computed: {
    positionOptions() {
      let careerPathType = -1;
      if (this.careerPathChange.changeType === 'expert') {
        careerPathType = 3;
      } else if (this.careerPathChange.changeType === 'leader') {
        careerPathType = 2;
      }

      return this.positions
        .filter((x) => x.type === careerPathType)
        .map((x) => {
          return {
            value: x.id,
            text: this.localize(x.name)
          };
        });
    }
  },

  watch: {
    developmentPath() {
      this.openPanel = this.developmentPath;
      this.emitChange();
    },

    value() {
      this.setData(this.value);
      this.openPanel = this.value.developmentPath;
    }
  },

  mounted() {
    this.setData(this.value);
    this.openPanel = this.value.developmentPath;
  },

  methods: {
    clear() {
      this.developmentPath = -1;
      this.improvement = {};

      this.developmentData = {
        0: {},
        1: {
          position: null,
          availableSkills: null,
          missingSkills: null,
          timetable: null
        },
        2: {
          position: null,
          availableSkills: null,
          missingSkills: null,
          timetable: null
        },
        3: {
          position: null,
          availableSkills: null,
          missingSkills: null,
          timetable: null
        },
        4: {
          position: null,
          availableSkills: null,
          missingSkills: null,
          timetable: null
        }
      };
    },

    emitChange() {
      const data = {
        developmentPath: this.developmentPath,
        developmentData: this.developmentData[this.developmentPath],
        targetPosition: this.targetPosition
      };

      this.$emit('input', data);
      this.$emit('change');
    },

    setData(val) {
      if (val !== null && val !== undefined && Object.keys(val).length > 0) {
        this.developmentData[val.developmentPath] = val.developmentData;
        this.developmentPath = val.developmentPath;
        this.openPanel = val.developmentPath;
      }
    },

    panelClicked(clickedPath) {
      if (this.readOnly && !this.preview) {
        return;
      }

      // collapse the panel if the active panel was clicked again
      if (this.openPanel === clickedPath) {
        this.openPanel = null;
        return;
      }

      const currentPanelIsCollapsed =
        this.openPanel === null && this.developmentPath === clickedPath;

      if (!this.hasData() || currentPanelIsCollapsed) {
        this.openPanel = clickedPath;
        this.developmentPath = clickedPath;
      } else {
        this.requestedDevelopmentPath = clickedPath;
        this.changeModal = true;
      }
    },

    acceptDialog() {
      this.openPanel = this.requestedDevelopmentPath;
      this.developmentPath = this.requestedDevelopmentPath;
      this.changeModal = false;
      this.dataChanged = false;

      // clear all data in any lateral development path except
      // for the one we're switching to
      for (let i = 0; i <= 4; i++) {
        if (i !== this.requestedDevelopmentPath) {
          if (i !== 0) {
            this.developmentData[i] = {
              position: null,
              availableSkills: null,
              missingSkills: null,
              timetable: null
            };
          } else {
            this.developmentData[i] = {};
          }
        }
      }
    },

    hasData() {
      if (this.developmentPath === null || this.developmentPath < 0) {
        return false;
      }

      const d = this.developmentData[this.developmentPath];

      if (!d) {
        return false;
      }
      return Object.values(d).some((x) => x !== null && x !== '');
    }
  },

  components: {
    lateralPath,
    changePathDialog
  }
};
</script>

<style></style>
