<template>
  <v-dialog v-model="showDialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">
          {{ $t('careerDevelopment.overwrite.title') }}
        </span>
      </v-card-title>
      <v-card-text>
        {{ $t('careerDevelopment.overwrite.info') }}<br /><br />{{
          $t('careerDevelopment.overwrite.confirm')
        }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('closed')">
          <slot name="close-button">{{ $t('cancel') }}</slot>
        </v-btn>
        <v-btn color="blue darken-1" text @click="$emit('continue')">
          <slot name="confirm-button">{{ $t('continue') }}</slot>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showDialog: {
      type: Boolean,
      required: true
    }
  }
};
</script>
