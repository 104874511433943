<template>
  <div
    class="px-1 px-sm-0 hays-development-component"
    id="development-component"
  >
    <h3 class="px-1 px-sm-0">{{ localize(value.title) }}</h3>

    <v-alert v-if="dataTransferred" class="mb-0" type="warning" text>
      {{
        $t('feedbacks.dataTransferred', {
          type: $t(`feedbacks.processTypes.${dataTransferProcessType}`)
        })
      }}
    </v-alert>

    <comparison
      v-if="feedbackData && feedbackData.isComparison && positions"
      :positions="positions"
      :feedbackData="feedbackData"
      :isFormFilled="isFormFilled"
      :readOnly="readOnly"
      :printView="printView"
      @selectData="transferFromComparison"
      ref="comparison"
    ></comparison>

    <v-expansion-panels
      v-if="!initializing && !feedbackData.canCompareOnly"
      v-model="selectedPanel"
      :mandatory="initialSelection && development !== null && development >= 0"
      :disabled="(readOnly && !preview) || printView"
      readonly
      flat
    >
      <v-expansion-panel
        class="hays-development-component"
        @click="panelClicked(0)"
      >
        <v-expansion-panel-header disable-icon-rotate>
          <template v-slot:actions v-if="development === 0">
            <v-icon color="white">mdi-check-circle</v-icon>
          </template>
          <template v-slot:actions v-else>
            <v-icon>mdi-chevron-down</v-icon>
          </template>
          <div>
            <v-icon> mdi-arrow-left-right-bold </v-icon>
            {{ $t('careerDevelopment.developmentType.0') }}
            <v-tooltip right max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on"> mdi-information </v-icon>
              </template>
              <span>{{ $t('careerDevelopment.lateralInfo') }}</span>
            </v-tooltip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4" eager>
          <lateral-development
            v-if="positions && feedbackData"
            v-model="lateralDevelopment"
            ref="lateralDevelopment"
            :positions="positions"
            :readOnly="readOnly || printView"
            :printView="printView"
            :preview="preview"
            :feedbackType="feedbackData.feedbackType"
            @change="emitData"
          ></lateral-development>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel
        class="hays-development-component"
        @click="panelClicked(1)"
      >
        <v-expansion-panel-header disable-icon-rotate>
          <template v-slot:actions v-if="development === 1">
            <v-icon color="white">mdi-check-circle</v-icon>
          </template>
          <template v-slot:actions v-else>
            <v-icon>mdi-chevron-down</v-icon>
          </template>

          <div>
            <v-icon> mdi-filter-variant mdi-rotate-180</v-icon>
            {{ $t('careerDevelopment.developmentType.1') }}
            <v-tooltip right max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on"> mdi-information </v-icon>
              </template>
              <span>{{ $t('careerDevelopment.hierarchicalInfo') }}</span>
            </v-tooltip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4" eager>
          <hierarchy-development
            v-if="positions && feedbackData"
            v-model="hierarchyDevelopment"
            ref="hierarchyDevelopment"
            :positions="positions"
            :feedbackType="feedbackData.feedbackType"
            :readOnly="readOnly || printView"
            :preview="preview"
            @change="emitData"
            @targetDateChanged="targetDateChanged"
          ></hierarchy-development>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <template v-else-if="printView">
      <comparison-section
        v-if="feedbackData && formData.data"
        :feedbackData="formData"
        :feedbackType="feedbackData.feedbackType"
        :positions="positions"
        readOnly
      >
      </comparison-section>
    </template>

    <change-path-dialog
      :showDialog="changeModal"
      @closed="changeModal = false"
      @continue="acceptDialog"
    ></change-path-dialog>
  </div>
</template>

<script>
import lateralDevelopment from './lateral.vue';
import hierarchyDevelopment from './hierarchy.vue';
import comparison from './comparison.vue';
import comparisonSection from './comparison-section.vue';
import changePathDialog from './change-path-dialog.vue';

import { mapActions, mapState, mapMutations } from 'vuex';
import { feedbackEnums } from '@/enums/feedbacks.js';
import { errorCodes } from '@/enums/errorCodes.js';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    },

    preview: {
      type: Boolean,
      required: false,
      default: false
    },

    transferredData: {
      type: Object,
      required: false,
      default: () => null
    }
  },

  data() {
    return {
      selectedPanel: null,
      feedbackData: null,
      initializing: true,
      development: null,
      positions: null,
      lateralDevelopment: {},
      hierarchyDevelopment: {},
      positionDevelopment: {},
      changeModal: false,
      requestedDevelopmentPath: null,
      requestedTransferData: null,
      isFormFilled: false,
      dataTransferComplete: false,
      dataTransferred: false,
      dataTransferProcessType: -1,
      initialSelection: true
    };
  },

  computed: {
    ...mapState('feedbacks', {
      currentFeedbackData: 'currentFeedbackData'
    }),

    formData() {
      const valueData = {};
      valueData.development = this.development;

      if (this.development === 0) {
        valueData.data = this.lateralDevelopment;
        valueData.targetPosition = null;
      } else if (this.development === 1) {
        valueData.data = this.hierarchyDevelopment;
        valueData.targetPosition = this.hierarchyDevelopment.targetPosition;
      } else if (this.development === 2) {
        valueData.data = this.positionDevelopment;
        valueData.targetPosition = this.positionDevelopment.targetPosition;
      }

      return valueData;
    },

    resultData() {
      return {
        feedbackId: this.$route.params.id,
        feedbackType: this.feedbackData.feedbackType,
        targetPosition: this.formData.targetPosition,
        targetDate: null,
        formData: this.formData
      };
    }
  },

  watch: {
    async development() {
      await this.emitData();
    }
  },

  async mounted() {
    this.setLoaded(false);
    this.positions = await this.getPositions();

    if (!this.preview) {
      this.feedbackData = this.currentFeedbackData;
    } else {
      this.feedbackData = {
        isComparison: false,
        feedbackType: feedbackEnums.feedbackTypes.SELF_EVALUATION
      };
    }

    await this.initializeData();
    this.setLoaded(true);
  },

  methods: {
    ...mapActions({
      getPositions: 'feedbacks/listDevelopmentPositions',
      saveData: 'feedbacks/saveCareerData',
      getData: 'careerDevelopment/getCareerData'
    }),

    ...mapMutations({
      setLoaded: 'careerDevelopment/setComponentLoaded'
    }),

    async emitData() {
      if (this.preview) {
        return;
      }

      // do not overwrite the data in the database while the component is
      // still initializing
      if (!this.initializing && !this.readOnly) {
        const result = await this.saveData(this.resultData);
        if (
          result.errorCode &&
          result.errorCode === errorCodes.errorCodes.UNEXPECTED_EXCEPTION
        ) {
          this.$emit('errorOnSave');
        }
      }

      this.$emit('input', this.value);
    },

    async initializeData() {
      const data = await this.getData(this.$route.params.id);

      if (data !== null && data !== '' && data !== '{}') {
        const dataObj = JSON.parse(data);
        if (dataObj.development === 0) {
          this.lateralDevelopment.developmentPath =
            dataObj.data.developmentPath;

          this.lateralDevelopment.developmentData =
            dataObj.data.developmentData;
        } else if (dataObj.development === 1) {
          this.hierarchyDevelopment.developmentPath =
            dataObj.data.developmentPath;
          this.hierarchyDevelopment.developmentData =
            dataObj.data.developmentData;
        }

        this.development = dataObj.development;
        this.selectedPanel = dataObj.development;
        this.initialSelection = false;
      }

      setTimeout(() => {
        this.isFormFilled =
          this.$refs.lateralDevelopment?.hasData() ||
          this.$refs.hierarchyDevelopment?.hasData();

        if (this.transferredData !== null) {
          this.setTransferredValue(this.transferredData);
        }
      }, 300);

      this.initializing = false;
    },

    setTransferredValue() {
      if (!this.value.transferFromElement) {
        return;
      }

      if (
        this.transferredData.careerData === null ||
        this.transferredData.careerData === '' ||
        (this.development !== null && this.development > -1)
      ) {
        return;
      }

      this.dataTransferred = true;

      const careerData = JSON.parse(this.transferredData.careerData);
      this.setTransferData(careerData);

      this.dataTransferProcessType = this.transferredData.processType;
      this.dataTransferComplete = true;
    },

    transferFromComparison(data) {
      // check if the form already contains data and if so we
      // need to display the prompt to ask if the user really
      // wants to change the data
      let hasData = false;
      if (this.development === 0) {
        hasData = this.$refs.lateralDevelopment.hasData();
      } else {
        hasData = this.$refs.hierarchyDevelopment.hasData();
      }

      if (hasData) {
        this.requestedTransferData = data;
        this.changeModal = true;

        return;
      }

      this.$refs.comparison.collapseAll();

      this.setTransferData(data);
    },

    setTransferData(transferData) {
      const data = JSON.parse(JSON.stringify(transferData));

      if (data.development === 0) {
        this.development = 0;

        setTimeout(() => {
          this.lateralDevelopment.developmentPath = data.data.developmentPath;
          this.lateralDevelopment.developmentData = data.data.developmentData;
          this.$refs.lateralDevelopment.setData(this.lateralDevelopment);
        }, 300);
      } else if (data.development === 1) {
        this.development = 1;

        setTimeout(() => {
          this.hierarchyDevelopment.developmentPath = data.data.developmentPath;
          this.hierarchyDevelopment.developmentData = data.data.developmentData;
          this.$refs.hierarchyDevelopment.setData(this.hierarchyDevelopment);
        }, 300);
      }

      this.development = data.development;
      this.requestedTransferData = null;
    },

    panelClicked(developmentPath) {
      if (this.development === developmentPath) {
        //  toggle the current panel if the already active
        // panel was clicked again
        if (this.selectedPanel === developmentPath) {
          this.selectedPanel = null;
        } else if (this.selectedPanel === null) {
          this.selectedPanel = developmentPath;
        }

        return;
      }

      let hasData = false;
      if (this.development === 0) {
        hasData = this.$refs.lateralDevelopment.hasData();
      } else {
        hasData = this.$refs.hierarchyDevelopment.hasData();
      }

      if (hasData) {
        this.requestedDevelopmentPath = developmentPath;
        this.changeModal = true;
      } else {
        this.development = developmentPath;
        this.selectedPanel = developmentPath;
      }

      this.initialSelection = false;
    },

    acceptDialog() {
      this.changeModal = false;

      if (this.requestedTransferData) {
        this.setTransferData(this.requestedTransferData);
        this.$refs.comparison.collapseAll();
      } else {
        this.development = this.requestedDevelopmentPath;
        this.selectedPanel = this.requestedDevelopmentPath;
      }

      if (this.development === 1) {
        this.$refs.lateralDevelopment.clear();
        this.$vuetify.goTo('#development-component');
      } else {
        this.$refs.hierarchyDevelopment.clear();
      }

      this.requestedDevelopmentPath = null;
    },

    targetDateChanged(targetDate) {
      this.resultData.targetDate = targetDate;
      this.emitData();
    }
  },

  components: {
    lateralDevelopment,
    hierarchyDevelopment,
    //positionDevelopment
    comparison,
    comparisonSection,
    changePathDialog
  }
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.hays-development-component {
  margin-top: 60px;
  .theme--light.v-expansion-panels .v-expansion-panel {
    margin-top: 16px;
    background-color: var(--element-background);
    border-radius: 4px;

    .v-expansion-panel-header {
      font-weight: bold;
      letter-spacing: 0.5px;
      padding-top: 24px;
      padding-bottom: 24px;
      border-radius: 4px;

      .v-expansion-panel-header__icon {
        transition: none;
      }
    }

    .v-expansion-panel-header--active {
      background-color: var(--v-secondary-base);
      color: #fff;
      box-shadow: 0px 10px 10px -3px rgba(0, 0, 0, 0.2);

      .v-icon {
        color: #fff;
      }
    }

    // nested panels have a white background
    .v-expansion-panel {
      background-color: #fff;
    }
  }

  .hays-development-component {
    & > .v-expansion-panel-header {
      background-color: var(--v-secondary-base);
      color: #fff;

      .v-icon {
        color: #fff;
      }
    }

    & > .v-expansion-panel-content {
      .v-expansion-panel-content__wrap {
        padding: 1em 4em 3em 4em;

        .v-expansion-panel-content__wrap {
          padding: 16px 24px;
        }
      }
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-expansion-panel-content__wrap {
      padding: 0 6px 16px;
    }

    .v-expansion-panel-header {
      padding: 16px 6px;
    }
  }

  .v-input.v-text-field--filled .v-input__slot {
    background-color: var(--element-background);
  }
}
</style>
