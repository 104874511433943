<template>
  <div>
    <v-expansion-panels
      v-model="openPanel"
      focusable
      flat
      :disabled="readOnly && !preview"
      :readonly="true"
    >
      <development-path
        v-model="developmentData[3]"
        :careerPathType="3"
        :positions="positions"
        :selectedCareerPath="developmentPath === 3"
        :feedbackType="feedbackType"
        :readOnly="readOnly"
        :preview="preview"
        @change="emitChange"
        @panelClicked="panelClicked(3)"
        @dateChanged="dateChanged"
      ></development-path>

      <development-path
        v-model="developmentData[2]"
        :careerPathType="2"
        :positions="positions"
        :selectedCareerPath="developmentPath === 2"
        :feedbackType="feedbackType"
        :readOnly="readOnly"
        :preview="preview"
        @change="emitChange"
        @panelClicked="panelClicked(2)"
        @dateChanged="dateChanged"
      ></development-path>

      <development-path
        v-model="developmentData[1]"
        :careerPathType="1"
        :positions="positions"
        :selectedCareerPath="developmentPath === 1"
        :feedbackType="feedbackType"
        :readOnly="readOnly"
        :preview="preview"
        @change="emitChange"
        @panelClicked="panelClicked(1)"
        @dateChanged="dateChanged"
      ></development-path>
    </v-expansion-panels>

    <change-path-dialog
      :showDialog="changeModal"
      @closed="changeModal = false"
      @continue="acceptDialog"
    ></change-path-dialog>
  </div>
</template>

<script>
import developmentPath from './develoment-path.vue';
import changePathDialog from './change-path-dialog.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    positions: {
      type: Array,
      required: true
    },

    feedbackType: {
      type: Number,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    preview: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      developmentType: null,
      developmentPath: -1,
      developmentData: {
        1: {
          targetPosition: null,
          availableSkills: null,
          missingSkills: null,
          promotionTime: null
        },
        2: {
          targetPosition: null,
          availableSkills: null,
          missingSkills: null,
          promotionTime: null
        },
        3: {
          targetPosition: null,
          availableSkills: null,
          missingSkills: null,
          promotionTime: null
        }
      },
      openPanel: null,
      changeModal: false,
      requestedDevelopmentPath: null
    };
  },

  computed: {
    targetPosition() {
      if (developmentPath === -1) {
        return null;
      }

      const path = this.developmentData[this.developmentPath];
      if (path === undefined) {
        return null;
      }

      return path.targetPosition;
    }
  },

  watch: {
    developmentPath() {
      this.emitChange();
    },

    value() {
      this.setData(this.value);
    }
  },

  mounted() {
    this.setData(this.value);
  },

  methods: {
    clear() {
      this.developmentType = null;
      this.developmentPath = -1;

      this.developmentData = {
        1: {
          targetPosition: null,
          availableSkills: null,
          missingSkills: null,
          promotionTime: null
        },
        2: {
          targetPosition: null,
          availableSkills: null,
          missingSkills: null,
          promotionTime: null
        },
        3: {
          targetPosition: null,
          availableSkills: null,
          missingSkills: null,
          promotionTime: null
        }
      };
    },

    dateChanged(targetDate) {
      this.$emit('targetDateChanged', targetDate);
    },

    emitChange() {
      const data = {
        developmentPath: this.developmentPath,
        developmentData: this.developmentData[this.developmentPath],
        targetPosition: this.targetPosition
      };

      this.$emit('input', data);
      this.$emit('change');
    },

    mapOpenPanel(developmentPath) {
      if (developmentPath === 3) {
        return 0;
      } else if (developmentPath === 2) {
        return 1;
      } else if (developmentPath === 1) {
        return 2;
      } else {
        return null;
      }
    },

    setData(val) {
      if (val !== null && val !== undefined && Object.keys(val).length > 0) {
        this.developmentData[val.developmentPath] = val.developmentData;
        this.developmentPath = val.developmentPath;

        this.openPanel = this.mapOpenPanel(val.developmentPath);
      }
    },

    panelClicked(clickedPath) {
      if (this.readOnly && !this.preview) {
        return;
      }

      // collapse the panel if the active panel was clicked again
      const index = this.mapOpenPanel(clickedPath);
      if (index === this.openPanel) {
        this.openPanel = null;
        return;
      }

      const currentPanelIsCollapsed =
        this.openPanel === null && this.developmentPath === clickedPath;

      if (!this.hasData() || currentPanelIsCollapsed) {
        this.openPanel = index;
        this.developmentPath = clickedPath;
      } else {
        this.requestedDevelopmentPath = clickedPath;
        this.changeModal = true;
      }
    },

    acceptDialog() {
      const index = this.mapOpenPanel(this.requestedDevelopmentPath);

      this.openPanel = index;
      this.developmentPath = this.requestedDevelopmentPath;
      this.changeModal = false;
      this.dataChanged = false;

      // clear all data in any hierarchical development path except
      // for the one we're switching to
      for (let i = 1; i <= 3; i++) {
        if (i !== this.requestedDevelopmentPath) {
          this.developmentData[i] = {
            targetPosition: null,
            availableSkills: null,
            missingSkills: null,
            promotionTime: null
          };
        }
      }
    },

    hasData() {
      if (this.developmentPath === null || this.developmentPath < 0) {
        return false;
      }

      const d = this.developmentData[this.developmentPath];

      if (!d) {
        return false;
      }
      return Object.values(d).some((x) => x !== null && x !== '');
    }
  },

  components: {
    developmentPath,
    changePathDialog
  }
};
</script>

<style></style>
